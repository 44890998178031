import React, { useState } from "react";
import styles from "./process.module.scss";
import classNames from "classnames";
import Heading from "components/heading/heading";
import { AnimatePresence, motion } from "framer-motion";
import Button from "components/button/button";
import image1 from "assets/images/process/1.svg";
import image2 from "assets/images/process/2.svg";
import image3 from "assets/images/process/3.svg";
import image4 from "assets/images/process/4.svg";
export default function Process() {
  const [tab, setTab] = useState("discovery");
  return (
    <div className={styles["process"]}>
      <div className="auto__container">
        <Heading
          type={"smSpace"}
          title="How We Bring Your Vision to Life"
          text="A streamlined process designed to turn your ideas into extraordinary digital experiences efficiently and collaboratively."
        />
        <div className={styles["process__btns"]}>
          <button
            type="button"
            onClick={() => setTab("discovery")}
            className={classNames({
              [styles.active]: tab === "discovery",
            })}
          >
            1. Discovery & Strategy
          </button>
          <button
            type="button"
            onClick={() => setTab("design")}
            className={classNames({
              [styles.active]: tab === "design",
            })}
          >
            2. Design & Prototype
          </button>
          <button
            type="button"
            onClick={() => setTab("dev")}
            className={classNames({
              [styles.active]: tab === "dev",
            })}
          >
            3. Development
          </button>
          <button
            type="button"
            onClick={() => setTab("launch")}
            className={classNames({
              [styles.active]: tab === "launch",
            })}
          >
            4. Launch & Optimization
          </button>
        </div>
        <div
          onClick={() => setTab("discovery")}
          className={classNames(styles["process__mob"], {
            [styles.active]: tab === "discovery",
          })}
        >
          {" "}
          1. Discovery & Strategy
        </div>
        {tab === "discovery" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            className={styles["process__inner"]}
          >
            <div className={styles["process__inner-content"]}>
              <div className={styles["process__inner-text"]}>
                <h4>Understanding Your Needs</h4>
                <p>
                  We start by getting to know your goals, challenges, and
                  vision. Through detailed consultations, we uncover the unique
                  aspects of your business to create a personalized strategy
                  that drives results.
                </p>
                <div className={styles["process__inner-content-tags"]}>
                  <p>Deliverables:</p>
                  <span>Project goals</span>
                  <span>target audience</span>
                  <span>competitive analysis</span>
                </div>
              </div>
              <Button text="Let’s Discuss Your Vision" type={"dark"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
            </div>
            <div className={styles["process__inner-image"]}>
              <img src={image1} alt="" />
            </div>
          </motion.div>
        )}
        <div
          onClick={() => setTab("design")}
          className={classNames(styles["process__mob"], {
            [styles.active]: tab === "design",
          })}
        >
          2. Design & Prototype
        </div>
        {tab === "design" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            className={styles["process__inner"]}
          >
            <div className={styles["process__inner-content"]}>
              <div className={styles["process__inner-text"]}>
                <h4>Bringing Your Ideas to Life</h4>
                <p>
                  Our design team translates your vision into wireframes and
                  prototypes that reflect the look, feel, and flow of your
                  product. We make sure the user experience is intuitive and
                  visually captivating.
                </p>
                <div className={styles["process__inner-content-tags"]}>
                  <p>Deliverables:</p>
                  <span>Wireframes</span>
                  <span>design mockups</span>
                  <span>prototypes</span>
                </div>
              </div>

              <Button text="Let’s Discuss Your Vision" type={"dark"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
            </div>
            <div className={styles["process__inner-image"]}>
              <img src={image2} alt="" />
            </div>
          </motion.div>
        )}
        <div
          onClick={() => setTab("dev")}
          className={classNames(styles["process__mob"], {
            [styles.active]: tab === "dev",
          })}
        >
          3. Development
        </div>
        {tab === "dev" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            className={styles["process__inner"]}
          >
            <div className={styles["process__inner-content"]}>
              <div className={styles["process__inner-text"]}>
                <h4>Building with Precision </h4>
                <p>
                  Once the design is approved, our development team brings it to
                  life with clean, scalable code. Whether it’s a web or mobile
                  app, we ensure everything functions seamlessly, with
                  performance and security in mind.
                </p>
                <div className={styles["process__inner-content-tags"]}>
                  <p>Deliverables:</p>
                  <span>web or mobile app</span>
                  <span>CRM integrations</span>
                  <span>blockchain solutions</span>
                </div>
              </div>

              <Button text="Let’s Discuss Your Vision" type={"dark"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
            </div>
            <div className={styles["process__inner-image"]}>
              <img src={image3} alt="" />
            </div>
          </motion.div>
        )}
        <div
          onClick={() => setTab("launch")}
          className={classNames(styles["process__mob"], {
            [styles.active]: tab === "launch",
          })}
        >
          4. Launch & Optimization
        </div>
        {tab === "launch" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            className={styles["process__inner"]}
          >
            <div className={styles["process__inner-content"]}>
              <div className={styles["process__inner-text"]}>
                <h4>Perfecting and Going Live </h4>
                <p>
                  Before going live, we rigorously test every aspect of your
                  product to ensure it’s ready for the real world. Post-launch,
                  we’re there to fine-tune, optimize, and support you for
                  long-term success. Deliverables: Final product launch, ongoing
                  support, and optimizations.
                </p>
                <div className={styles["process__inner-content-tags"]}>
                  <p>Deliverables:</p>
                  <span>Final product launch</span>
                  <span>ongoing support</span>
                  <span>optimizations</span>
                </div>
              </div>
              <Button text="Let’s Discuss Your Vision" type={"dark"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
            </div>
            <div className={styles["process__inner-image"]}>
              <img src={image4} alt="" />
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
