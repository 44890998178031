import React from "react";
import styles from "./contact.module.scss";
import Button from "components/button/button";
import Heading from "components/heading/heading";
export default function Contact() {
  return (
    <div className={styles["contact"]}>
      <div className="auto__container">
        <div className={styles["contact__inner"]}>
          <Heading
            title={"Let’s Bring Your Vision to Life!"}
            text={
              "Ready to start your next project or just have a question? Reach out, and let’s chat!"
            }
          />
          <div className={styles["contact__inner-btns"]}>
            <Button text="Book a Call with Us" type={"primary"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
            <Button text="Send Us a Mail" type={"dark"} onClick={() => {window.location.href = "mailto:contact@digitsky.io"}} />
          </div>
        </div>
      </div>
    </div>
  );
}
