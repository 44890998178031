import React, { useState } from "react";
import styles from "./faq.module.scss";
import Button from "components/button/button";
import Heading from "components/heading/heading";
import { chevronBottom, plusIcon } from "base/SVG";
import { AnimatePresence, motion } from "framer-motion";
import icon from "assets/images/faq.svg";
import classNames from "classnames";
const faqList = [
  /*{
    id: "1",
    question:
      "What’s the difference between a one-off project and a subscription plan?",
    answer:
      "With a one-off project, we provide a single service, like a website design, branding package, or app development, delivered as a complete project. Our subscription plans, however, offer continuous support each month. This includes updates, new features, and ongoing adjustments to keep your digital presence fresh and aligned with your business goals.",
  },*/
  /*{
    id: "2",
    question: "Can I change or cancel my subscription plan at any time?",
    answer:
      "Yes, you can switch or cancel your plan anytime—no hassle.",
  },*/
  {
    id: "3",
    question: "How do we communicate and keep track of progress?",
    answer:
      "We use tools like Trello or Asana for updates. Communication is via email, video calls, or instant messaging.",
  },
  {
    id: "4",
    question: "What types of businesses do you work with?",
    answer:
      "We work with startups and established companies across industries like e-commerce, healthcare, and tech.",
  },
  /*{
    id: "5",
    question: "What’s included in a typical monthly subscription?",
    answer:
      "Regular updates, feature development, performance improvements, and UX/UI enhancements tailored to your needs.",
  },*/
  {
    id: "6",
    question: "How soon can you start on my project?",
    answer:
      "We usually start within 1–3 days after finalizing project details.",
  },
  {
    id: "7",
    question: "Why shouldn’t I just hire a full-time professional?",
    answer:
      "With us, you get a full team of experts at a lower cost and more flexiblity, covering everything from design to development.",
  },
  /*{
    id: "8",
    question: "Is there a limit to how many requests I can have?",
    answer:
      "Request limits depend on your plan. Higher tiers offer more flexibility.",
  },*/
];
export default function Faq() {
  const [isOpen, setIsOpen] = useState("1");
  return (
    <div className={styles["faq"]}>
      <div className="auto__container">
        <Heading
          title={"Commonly Asked Questions"}
          text={"Got questions? We’ve got answers!"}
        />
        <div className={styles["faq__inner"]}>
          <div className={styles["faq__inner-items"]}>
            {faqList.map((item, index) => {
              return (
                <FaqItem
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  itemData={item}
                  key={index}
                />
              );
            })}
          </div>
          <div className={styles["faq__inner-content"]}>
            <div className={styles["faq__inner-content-icon"]}>
              <img src={icon} alt="icon" />
            </div>
            <h3>
              Book a 15min Intro call <br /> to tell us about your project
            </h3>
            <Button type={"uniq"} text={"Book Now"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
          </div>
        </div>
      </div>
    </div>
  );
}
const FaqItem = ({ itemData, isOpen, setIsOpen }) => {
  return (
    <div className={styles["faqItem"]}>
      <div
        className={classNames(styles["faqItem__head"], {
          [styles.active]: isOpen === itemData.id,
        })}
        onClick={() => {
          if (isOpen !== itemData.id) {
            setIsOpen(itemData.id);
          } else {
            setIsOpen(null);
          }
        }}
      >
        <button type="button">{plusIcon}</button>

        <h6>{itemData.question}</h6>
        <span>{chevronBottom}</span>
      </div>
      <AnimatePresence>
        {isOpen === itemData.id && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
            className={styles["faqItem__body"]}
          >
            {itemData.answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
