import React, { useEffect, useState } from "react";
import logo from "assets/images/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./header.module.scss";
import classNames from "classnames";
export default function Header() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    
    /*window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");*/

  }, [location]);
  useEffect(() => {

    /*const windowScroll = () => {
      const links = document.querySelectorAll(".anchorLinks");
      const sections = document.querySelectorAll(".anchor");
      let index = sections.length;
      while (--index && window.scrollY + 100 < sections[index]?.offsetTop) {}
      links.forEach((link) => link.classList.remove("active"));
      links[index]?.classList.add("active");
    };
    windowScroll();
    window.addEventListener("scroll", windowScroll);
    return () => window.removeEventListener("scroll", windowScroll);*/
  }, []);
  return (
    <div className={styles["header"]}>
      <div className="auto__container">
        <div className={styles["header__inner"]}>
          <Link to="/" className={styles["header__inner-logo"]}>
            <img src={logo} alt="logo" />
          </Link>
          <div
            onClick={close}
            className={classNames(styles["nav"], {
              [styles.active]: menu,
            })}
          >
            <div className={styles["nav__inner"]}>
              <a href="#top" className="anchorLinks hidden active">
                home
              </a>
              <a href="#services" className="anchorLinks">
                Services
              </a>
              <a href="#process" className="anchorLinks">
                Vision
              </a>
              <a href="#plans" className="anchorLinks">
                Process
              </a>
              <a href="#portfolio" className="anchorLinks">
                Portfolio
              </a>
              <a href="#faq" className="anchorLinks">
                FAQs
              </a>
              <a href="#contact" className="anchorLinks">
                Hire Us
              </a>
            </div>
          </div>
          <div
            className={classNames(styles["burger"], {
              [styles.active]: menu,
            })}
            onClick={() => setMenu(!menu)}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
