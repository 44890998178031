import React, { useState } from "react";
import styles from "./price.module.scss";
import Heading from "components/heading/heading";
import classNames from "classnames";
import Button from "components/button/button";
import { pricePro, priceStandard } from "./modul";
import rocket from "assets/images/rocket.svg";
export default function Price() {
  const [tab, setTab] = useState("standard");
  return (
    <div className={styles["price"]} id="get-going">
      <div className="auto__container" id="pricing">
        <Heading
          type={"smSpace"}
          title="Flexible Plans for You"
          text="Whether you need ongoing support or a partner to evolve with you, our work will keep your company thriving."
        />
        {/*<div className={styles["price__inner"]}>
          <div className={styles["price__btns"]}>
            <button
              type="button"
              onClick={() => setTab("standard")}
              className={classNames({
                [styles.active]: tab === "standard",
              })}
            >
              Standard
            </button>
            <button
              type="button"
              onClick={() => setTab("pro")}
              className={classNames({
                [styles.active]: tab === "pro",
              })}
            >
              Pro
            </button>
          </div>
          {tab === "standard" && (
            <div className={styles["price__inner-row"]}>
              {priceStandard.map((item, index) => {
                return <PriceItem {...item} key={index} />;
              })}
            </div>
          )}
          {tab === "pro" && (
            <div className={styles["price__inner-row"]}>
              {pricePro.map((item, index) => {
                return <PriceItem {...item} key={index} />;
              })}
            </div>
          )}
        </div>*/}
        <div className={styles["join"]}>
          <div className={styles["join__content"]}>
            <h2>Ready to get going?</h2>
            <p>
              At Digitsky, we know that some projects just need a single touch.
              Whether it’s a quick website update, a fresh logo design, or a
              short-term marketing boost, we’re here to help. Hire our expert team on a one-off basis, no
              strings attached or hire us for ongoing support.
            </p>
            <Button type={"gray"} text={"Book a Call with Us"}  onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
          </div>
          <div className={styles["join__image"]}>
            <img src={rocket} alt="rocket" />
          </div>
        </div>
      </div>
    </div>
  );
}
const PriceItem = (props) => {
  return (
    <div className={styles["priceItem"]}>
      <div className={styles["priceItem__content"]}>
        <h4>{props.title}</h4>
        <p>{props.type}</p>
        <div className={styles["priceItem__content-price"]}>
          ${props.price} <span>/month</span>
        </div>
        <h6 className="sm">Includes:</h6>
        <ul>
          {props?.list?.map((item, index) => {
            return (
              <li key={index}>
                <span></span>
                {item.text}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="priceItem__foot">
        <Button type={"dark"} text="Subscribe" onClick={() => {window.open(props.link, "_blank")}} />
      </div>
    </div>
  );
};
