import React from "react";
import styles from "./partners.module.scss";
import image1 from "assets/images/partners/dymen.png";
import image2 from "assets/images/partners/nextblock.png";
import image3 from "assets/images/partners/multi.png";
import image4 from "assets/images/partners/bearex.png";
import image5 from "assets/images/partners/acrylic.png";
import image6 from "assets/images/partners/casino.png";
import image7 from "assets/images/partners/metorbike.png";
const partnerList = [
  {
    id: "1",
    image: image1,
    url: "",
  },
  {
    id: "2",
    image: image2,
    url: "",
  },
  {
    id: "3",
    image: image3,
    url: "",
  },
  {
    id: "4",
    image: image4,
    url: "",
  },
  {
    id: "5",
    image: image5,
    url: "",
  },
  {
    id: "6",
    image: image7,
    url: "",
  },
];
export default function Partners() {
  return (
    <div className={styles["partners"]}>
      <div className="auto__container">
        <div className={styles["partners__title"]}>
          <p>We have worked on over 100+ projects</p>
        </div>
        <div
        
        className={styles["partners__inner"]}>
          {partnerList.map((item, index) => {
            return (
              <a
                href={item.url}
                className={styles["partnersItem"]}
                key={index}
              >
                <img src={item.image} alt="" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
