import React from "react";
import logo from "assets/images/logo.svg";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import { instagramIcon, linkedIn, twitterIcon } from "base/SVG";
export default function Footer() {
  return (
    <div className={styles["footer"]}>
      <div className="auto__container">
        <div className={styles["footer__inner"]}>
          <div className={styles["footer__inner-col"]}>
            <div className={styles["footer__inner-logo"]}>
              <img src={logo} alt="" />
            </div>
            <p>
              We are a design, SEO and development agency that transforms digital
              visions into reality.
            </p>
          </div>
          <div className={styles["footer__inner-col"]}>
            <h6>Links</h6>
            <a href="#services" className={styles["footer__inner-link"]}>
                Services
            </a>
            <a href="#process" className={styles["footer__inner-link"]}>
                Vision
            </a>
            <a href="#plans" className={styles["footer__inner-link"]}>
                Process
            </a>
            <a href="#portfolio" className={styles["footer__inner-link"]}>
                Portfolio
            </a>
            <a href="#faq" className={styles["footer__inner-link"]}>
                FAQs
            </a>
          </div>
          <div className={styles["footer__inner-col"]}>
            <h6>Let’s Connect</h6>
            <a
              href="mailto:contact@digitsky.io"
              className={styles["footer__inner-link"]}
            >
              Contact@digitsky.io
            </a>
            {/*<a
              href="tel:+4911110000000"
              className={styles["footer__inner-link"]}
            >
              +49 1111 0000000
            </a>*/}
          </div>
          {/*<div className={styles["footer__inner-col"]}>
            <h6>About</h6>
            <Link to="" className={styles["footer__inner-link"]}>
              Terms of Service
            </Link>
            <Link to="" className={styles["footer__inner-link"]}>
              Privacy Policy
            </Link>
          </div>*/}
          {/*<div className={styles["footer__inner-col"]}>
            <h6>Follow Us</h6>
            <div className={styles["footer__inner-socials"]}>
              <a href="">{twitterIcon}</a>
              <a href="">{instagramIcon}</a>
              <a href="">{linkedIn}</a>
            </div>
          </div>*/}
        </div>
        <div className={styles["footer__copy"]}>
          © 2025 Digitsky. All rights reserved.
        </div>
      </div>
    </div>
  );
}
