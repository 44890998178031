import Heading from "components/heading/heading";
import React from "react";
import styles from "./portfolio.module.scss";
import image1 from "assets/images/portfolio/1.png";
import image2 from "assets/images/portfolio/2.png";
import image3 from "assets/images/portfolio/3.png";
import image4 from "assets/images/portfolio/4.png";
import image5 from "assets/images/portfolio/5.png";
import image6 from "assets/images/portfolio/6.png";
import image7 from "assets/images/portfolio/7.png";

const gallery = [
  {
    id: "1",
    title: "Keyboard Part Custom Builder",
    text: "An online tool to build mechanical custom keyboard",
    image: image1,
    link: "https://keyboardpartpicker.io/"
  },
  {
    id: "2",
    title: "NextBlock Private Nodes",
    text: "A platform which sells private nodes for blockchain networks.",
    image: image2,
    link: "https://nextblock.io/"
  },
  {
    id: "3",
    title: "Sports Event Organizer",
    text: "A platform for organizing sports events and managing team stats.",
    image: image3,
    link: "https://www.figma.com/design/F6DZbcVAmie0zltjHVOvQR/Playmate?node-id=0-1"
  },
  {
    id: "4",
    title: "Royalty-Free Music & Sound Effects",
    text: "A large library of royalty-free music and sound effects",
    image: image4,
    link: "https://www.figma.com/design/xPculXrXZbMyEfayGbe0lQ/Acrylic-Label?node-id=0-1"
  },
  {
    id: "5",
    title: "Dymensify Cross Chain Bridge",
    text: "Connect blockchain networks for seamless asset transfers.",
    image: image5,
    link: "https://www.figma.com/design/hpMVbIYCsw5BIpQNqZiGKN/Dymensify-New?node-id=0-1"
  },
  {
    id: "6",
    title: "MetorBike RiderClub",
    text: "A social Platform for Owners of MetorBike",
    image: image7,
    link: "https://metorbike-riderclub.de/"
  },
];
export default function Portfolio() {
  return (
    <div className={styles["portfolio"]}>
      <div className="auto__container">
        <Heading
          title={"Check Out Some of Our Latest Creations"}
          text={"From concept to launch, our projects speak for themselves."}
        />
        <div className={styles["portfolio__inner"]}>
          {gallery.map((item, index) => {
            return (
              <div
                onClick={() => {
                  if (item.link) {
                    window
                      .open(item.link, "_blank")
                      .focus();
                  }
                }}
                className={styles["portfolioItem"]} key={index}>
                <div className={styles["portfolioItem__image"]}>
                  <img src={item.image} alt="image" />
                </div>
                <div className={styles["portfolioItem__content"]}>
                  <h5>{item.title}</h5>
                  <p>{item.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
