import React from "react";
import styles from "./services.module.scss";
import Heading from "components/heading/heading";
import Button from "components/button/button";
import image1 from "assets/images/services/image1.svg";
import image2 from "assets/images/services/image2.svg";
import image3 from "assets/images/services/image3.svg";
export default function Services() {
  return (
    <div className={styles["services"]}>
      <div className="auto__container">
        <Heading
          title={"Your Vision, Our Expertise."}
          text={
            "At Digitsky, we craft digital solutions that solve real problems, powered by the diverse expertise of our team."
          }
        />
        <div className={styles["services__inner"]}>
          <div className={styles["top"]}>
            <div className={styles["top__col"]}>
              <div className={styles["servicesItem"]}>
                <div className={styles["servicesItem__content"]}>
                  <h4>Web & Mobile App Development</h4>
                  <p>
                    From sleek websites to powerful mobile apps, we build
                    platforms that look great and perform even better. Our
                    development team turns ideas into user-friendly digital
                    experiences tailored to your goals.
                  </p>
                  <Button type={"dark"} text={"Get Going"} onClick={() => {window.location.href = "#get-going"}} />
                  <div className={styles["image_mobile"]}>
                    <img src={image1} alt="image" />
                  </div>
                </div>
                <div className={styles["servicesItem__image"]}>
                  <img src={image1} alt="image" />
                </div>
              </div>
            </div>
            <div className={styles["top__wrap"]}>
              <div className={styles["top__wrap-item"]}>
                <div className={styles["servicesItem"]}>
                  <div className={styles["servicesItem__content"]}>
                    <h4>UI/UX Design</h4>
                    <p>
                      We don’t just design interfaces, we create experiences.
                      With a focus on user flow and intuitive design, we make
                      sure your product isn’t just beautiful, it’s also easy to
                      use and keeps people coming back.
                    </p>
                    <Button type={"dark"} text={"Get Going"} onClick={() => {window.location.href = "#get-going"}} />
                  </div>
                  <div className={styles["servicesItem__image"]}>
                    <img src={image2} alt="image" />
                  </div>
                </div>
              </div>{" "}
              <div className={styles["top__wrap-item"]}>
                <div className={styles["servicesItem"]}>
                  <div className={styles["servicesItem__content"]}>
                    <h4>CRM Solutions</h4>
                    <p>
                      Manage your customer relationships like never before. Our
                      custom CRM solutions streamline your process, boost
                      productivity, and help you build stronger connections with
                      your clients.
                    </p>
                  </div>
                  <Button type={"dark"} text={"Get Going"} onClick={() => {window.location.href = "#get-going"}} />
                </div>
              </div>{" "}
              <div className={styles["top__wrap-item"]}>
                <div className={styles["servicesItem"]}>
                  <div className={styles["servicesItem__content"]}>
                    <h4>Branding</h4>
                    <p>
                      Your brand is more than just a logo, it's your story. We
                      help you tell that story through bold, memorable branding
                      that stands out and resonates with your audience.
                    </p>
                  </div>
                  <Button type={"dark"} text={"Get Going"} onClick={() => {window.location.href = "#get-going"}} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles["bot"]}>
            <div className={styles["bot__col"]}>
              <div className={styles["servicesItem"]}>
                <div className={styles["servicesItem__content"]}>
                  <h4>Blockchain Development</h4>
                  <p>
                    Ready to embrace the future? Our blockchain solutions are
                    designed to provide secure, decentralized applications for
                    businesses looking to innovate and stay ahead of the curve.
                  </p>
                </div>
                <Button type={"gray"} text={"Get Going"} onClick={() => {window.location.href = "#get-going"}} />

                <div className={styles["servicesItem__image"]}>
                  <img src={image3} alt="image" />
                </div>
              </div>
            </div>{" "}
            <div className={styles["bot__col"]}>
              <div className={styles["servicesItem"]}>
                <div className={styles["servicesItem__content"]}>
                  <h4>Digital Marketing</h4>
                  <p>
                    Drive traffic, generate leads, and boost conversions with
                    our tailored digital marketing strategies. From SEO to
                    social media campaigns, we help you reach the right audience
                    and achieve your business goals.
                  </p>
                </div>
                <Button type={"dark"} text={"Get Going"} onClick={() => {window.location.href = "#get-going"}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
