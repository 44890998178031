import React from "react";
import styles from "./plans.module.scss";
import Heading from "components/heading/heading";
import avatar from "assets/images/avatar.png";
const planList = [
  /*{
    id: "1",
    title: "Choose Your Plan",
    text: "Whether you’re looking for development, design, branding, or digital marketing, we’ve got you covered. Explore our comprehensive plans and select the one that aligns with your business goals.",
  },*/
  {
    id: "2",
    title: "1. Strategy Session",
    text: "Once you’re ready to go, we’ll set up an initial consultation to dive into your specific needs, goals, and vision. We use this session to tailor our approach, ensuring your plan is customized to drive the best results.",
  },
  {
    id: "3",
    title: "2. Get Your Trello Dashboard",
    text: "Next we’ll create a personalized Trello board for you to add your brief. This dashboard lets you see what we’re working on, check progress, and even add requests as your needs evolve.",
  },
  {
    id: "1",
    title: "3. Ongoing Support and Updates",
    text: "With your plan in place, our team gets to work! Every week, we’ll deliver updates on your plan. From design tweaks and new features to optimized marketing campaigns, we handle it all so you can focus on your business.",
  },
];
export default function Plans() {
  return (
    <div className={styles["plans"]}>
      <div className="auto__container">
        <Heading
          title={"How Our Process Works"}
          text={
            "Effortless, ongoing support to keep your digital presence at its best."
          }
        />
        <div className={styles["plans__inner"]}>
          <div className={styles["plans__inner-quote"]}>
            <h3>
              Partnering with these guys transformed our business. Their
              innovative solutions have gone above and beyond our expectations.
              We’ve seen remarkable improvements across the board.
            </h3>
            <div className={styles["plans__inner-info"]}>
              <div className={styles["plans__inner-info-text"]}>
                <h6>Thomas Jurasek</h6>
                <p>CEO, Bearex</p>
              </div>
              <div className={styles["plans__inner-info-avatar"]}>
                <img src={avatar} alt="" />
              </div>
            </div>
          </div>
          <div className={styles["plans__inner-items"]}>
            {planList.map((item, index) => {
              return (
                <div className={styles["plans__inner-item"]} key={index}>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
